<template>
    <div class="vstack gap-3">
        <div class="hstack gap-3 bg-white rounded py-3 px-4 justify-content-between">
            <div class="hstack gap-3">
                <div>
                    <b-iconstack font-scale="5">
                        <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                        <b-icon stacked icon="window" scale="0.5" variant="white"></b-icon>
                        <b-icon stacked icon="circle-fill" scale="0.4" shift-v="-4" variant="green"></b-icon>
                        <b-icon stacked icon="hand-index" scale="0.4" shift-v="-3" shift-h="-.5" variant="white"></b-icon>
                        <b-icon stacked icon="square-fill" scale="0.25" shift-v="-5.5" variant="green"></b-icon>
                    </b-iconstack>
                </div>
                <div>
                    <div class="h6 m-0">
                        Relatórios de Acessos
                    </div>
                    <div class="text-secondary">
                        {{ totalLogs | decimalSpacing }} Acessos
                    </div>
                </div>
            </div>
            <div class="hstack gap-3">
                <b-dropdown ref="dropdownDate" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="dateFilterText">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="1">
                            <div class="ms-1 small-text">Hoje</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="2">
                            <div class="ms-1 small-text">Esta Semana</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="3">
                            <div class="ms-1 small-text">Este Mês</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="4">
                            <div class="ms-1 small-text">Período</div>
                        </b-form-radio>
                    </div>
                    <div class="vstack gap-3 p-3 pt-0" v-if="filters.date">
                        <div class="vstack gap-3" v-if="filters.date == 4">
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Inicial:">
                                <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                            </b-form-group>
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Final:">
                                <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                            </b-form-group>
                        </div>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-dropdown ref="dropdownType" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="filters.type == 'channel_manager' ? 'Supervisor' : filters.type == 'operator' ? 'Operador' : 'Filtrar por'">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.type" name="radio-type" value="channel_manager">
                            <div class="ms-1 small-text">Supervisor</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.type" name="radio-type" value="operator">
                            <div class="ms-1 small-text">Operador</div>
                        </b-form-radio>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type == 'operator'">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.operatorType">
                            <b-form-select-option value="department">Departamento</b-form-select-option>
                            <b-form-select-option value="operator">Operador</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type == 'operator' && filters.operatorType == 'department'">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.departmentId">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type == 'channel_manager' || (filters.type == 'operator' && filters.operatorType == 'operator')">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.id" v-if="filters.type == 'channel_manager'">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in managers" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.id" v-else-if="filters.type == 'operator'">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownType">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-button class="rounded-pill py-0 px-4 text-white fw-semibold" variant="green" @click="getData(1)" :disabled="!filters.date || !filters.type">
                    Gerar
                </b-button>
            </div>
        </div>
        <div class="p-5 text-center bg-white rounded" v-if="loadingLogs">
            <b-spinner></b-spinner>
        </div>
        <div class="vstack bg-white rounded" v-else-if="logs.length">
            <b-row class="m-0 text-purple">
                <b-col class="p-4"></b-col>
                <b-col class="p-4 fw-bold">
                    Data de Acesso
                </b-col>
                <b-col class="p-4 fw-bold">
                    IP Externo
                </b-col>
                <b-col class="p-4 fw-bold">
                    IP Local
                </b-col>
                <b-col class="p-4 fw-bold">
                    Cidade/Estado
                </b-col>
                <b-col class="p-4 fw-bold" cols="2">
                    País
                </b-col>
            </b-row>
            <b-row class="m-0 border-top text-purple" v-for="log in logs" :key="log._id">
                <b-col class="p-0 hstack gap-2 p-4 bg-purple">
                    <b-avatar class="bg-secondary text-white border-4-light-purple" size="4.5em"></b-avatar>
                    <div class="vstack gap-1 text-light lh-1">
                        <div class="fw-semibold text-truncate">
                            {{ log.name }}
                        </div>
                        <div>
                            {{ roleFormat(log.role) }}
                        </div>
                        <div :class="`${log.authentication ? 'text-green' : 'text-red'}`">
                            {{ log.authentication ? 'Logou' : 'Deslogou' }}
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 hstack gap-2 p-4">
                    {{ log.accessTime | date("DD/MM/YY [Às] HH[ hs ]mm[ min]") }}
                </b-col>
                <b-col class="p-0 hstack gap-2 p-4">
                    {{ log.externalIP || "- - - -" }}
                </b-col>
                <b-col class="p-0 hstack gap-2 p-4">
                    {{ log.internalIP || "- - - -" }}
                </b-col>
                <b-col class="p-0 hstack gap-2 p-4">
                    <span v-if="log.city">
                        {{ log.city }}<span v-if="log.region">/{{ estadoCompletoParaSigla(log.region) }}</span>
                    </span>
                    <span v-else>
                        {{ estadoCompletoParaSigla(log.region) || "- - - -" }}
                    </span>
                </b-col>
                <b-col class="p-0 hstack gap-2 p-4 justify-content-between" cols="2">
                    <div>
                        {{ log.country }}
                    </div>
                    <div>
                        <b-button class="text-white rounded-circle py-1 px-2" variant="green" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Ver Mais" v-b-modal.viewLogStatus @click="selectLog(log)" v-if="log.authentication && log.role == 'operator'">
                            <b-icon icon="eye"></b-icon>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-between align-items-center px-4 py-3 border-top" v-if="totalLogs > 10">
                <b-pagination class="m-0"
                    v-model="currentPage"
                    :total-rows="totalLogs"
                    per-page="10"
                    @change="pageChange"
                ></b-pagination>
                <span>
                    <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * 10) - 10 + 1 : currentPage }} - {{(currentPage * 10 > totalLogs) ? totalLogs : currentPage * 10}} de {{ totalLogs }} Acessos</div>
                </span>
            </div>
        </div>
        <div class="p-5 text-center bg-white rounded text-secondary" v-else-if="loadedLogs">
            Nenhum dado encontrado!
        </div>
        <b-modal id="viewLogStatus" ref="viewLogStatus" title="Relatório de Acesso" body-class="p-0" header-class="py-1" hide-footer v-if="selectedLog">
            <div class="vstack">
                <div class="vstack gap-2 align-items-center bg-purple p-5 lh-1 text-light">
                    <b-avatar class="bg-secondary border-3-light-purple" size="6em" :url="selectedLog.avatar"></b-avatar>
                    <h5 class="m-0 text-truncate">
                        {{ selectedLog.name }}
                    </h5>
                    <div>
                        {{ roleFormat(selectedLog.role) }}
                    </div>
                </div>
            </div>
            <div class="vstack text-white overflow-y-auto h-50">
                <div class="px-4 py-2 bg-light-purple3 fw-semibold">
                    Data de Acesso
                </div>
                <div class="px-4 py-2 text-light-purple3 small-text">
                    {{ selectedLog.accessTime | date("MMM DD [● Às] HH[ hs ]mm[ min]") | capitalize }}
                </div>
                <div class="px-4 py-2 bg-light-purple3 fw-semibold">
                    Cronologia de Acesso
                </div>
                <div :class="`px-4 py-2 small-text ${ status.isBreak ? status.breakStart ? 'bg-green' : 'bg-red' : status.status ? 'bg-green' : 'bg-red' }`" v-for="status in selectedLog.accessStatus" :key="status._id">
                    <span class="fw-semibold">{{ status.isBreak ? status.breakStart ? 'Início de Intervalo' : 'Fim de Intervalo' : status.status ? 'Online' : 'Offline' }}</span>: {{ status.accessTime | date("HH[ hs ]mm[ min]") }}
                </div>
                <div class="px-4 py-2 bg-light-purple3 fw-semibold">
                    <b-row class="m-0">
                        <b-col class="p-0 text-uppercase">Ip Externo</b-col>
                        <b-col class="p-0 text-uppercase">Ip Local</b-col>
                    </b-row>
                </div>
                <div class="px-4 py-2 text-light-purple3 small-text">
                    <b-row class="m-0">
                        <b-col class="p-0">{{ selectedLog.externalIP || '- - - -' }}</b-col>
                        <b-col class="p-0">{{ selectedLog.internalIP || '- - - -' }}</b-col>
                    </b-row>
                </div>
                <div class="px-4 py-2 bg-light-purple3 fw-semibold">
                    <b-row class="m-0">
                        <b-col class="p-0">Cidade/Estado</b-col>
                        <b-col class="p-0">País</b-col>
                    </b-row>
                </div>
                <div class="px-4 py-2 text-light-purple3 small-text">
                    <b-row class="m-0">
                        <b-col class="p-0">
                            <span v-if="selectedLog.city">
                                {{ selectedLog.city }}<span v-if="selectedLog.region">/{{ estadoCompletoParaSigla(selectedLog.region) }}</span>
                            </span>
                            <span v-else>
                                {{ estadoCompletoParaSigla(selectedLog.region) || "- - - -" }}
                            </span>
                        </b-col>
                        <b-col class="p-0">
                            {{ selectedLog.country }}
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-modal>
        <FAB :screenshotBtn="true" />
    </div>
</template>

<script>
import api from '../services/apiService'
import utils from '../utils/utils'
import FAB from './FAB.vue'

export default {
    props: [
        "user", 
        "timestampDiff"
    ],
    components: {
        FAB
    },
    mounted() {
        this.getManagers()
        this.getOperators()
        this.getDepartments()
        this.onCloseModal()
    },
    data() {
        return {
            filters: {},
            managers: [],
            operators: [],
            departments: [],
            logs: [],
            totalLogs: 0,
            loadingLogs: false,
            loadedLogs: false,
            selectedLog: null,
            currentPage: 1,
        }
    },
    computed: {
        dateFilterText() {
            let text = ''
            switch(this.filters.date) {
                case 1:
                case '1':
                    text = 'Hoje'
                    break
                case 2:
                case '2':
                    text = 'Esta Semana'
                    break
                case 3:
                case '3':
                    text = 'Este Mês'
                    break
                case 5:
                case '5':
                    text = 'Este Ano'
                    break
                default:
                    text = 'Período'
            }
            return text
        },
    },
    methods: {
        async getData(page = 1)  {
            this.loadingLogs = true
            this.filters.channelId = this.user.channelId || this.user.roleId
            this.filters.firstPeriod = this.filters.startDate
            this.filters.finalPeriod = this.filters.endDate
            const resp = await api.getAccessLog({ filters: this.filters }, page)
            console.log({ resp })
            this.loadingLogs = false
            if(resp.statusCode == 200) {
                this.logs = resp.accessLog
                this.totalLogs = resp.total
            } else {
                this.logs = []
                this.totalLogs = 0
            }

            console.log(this.logs)

            if(!this.loadedLogs)
                this.loadedLogs = true
        },
        async getOperators() {
            let resp = await api.getOperators(this.user.channelId || this.user.roleId)
            // console.log({resp})
            if(resp.statusCode != 200) {
                this.operators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getManagers() {
            let resp = await api.getManagers(this.user.channelId || this.user.roleId)
            // console.log({resp})
            if(resp.statusCode != 200) {
                this.managers = []
                return
            }

            this.managers = resp.managers
        },
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        selectLog(log) {
            this.selectedLog = log
        },
        roleFormat(role) {
            switch(role) {
                case "channel_manager":
                    return "Supervisor"
                case "channel":
                    return "Canal"
                case "operator":
                    return "Operador"
                case "admin":
                    return "Admin"
                default:
                    return "- - - -"
            }
        },
        estadoCompletoParaSigla(estado) {
            return utils.estadoCompletoParaSigla(estado)
        },
        pageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.getData(e)
            }
        },
        onCloseModal() {
            this.selectedLog = null
        },
        closeDropdownDate() {
            this.$refs.dropdownDate.hide(true)
        },
        closeDropdownType() {
            this.$refs.dropdownType.hide(true)
        },
    }
}
</script>